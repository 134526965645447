import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import '../style/login';
import '../style/app_colors'
import '../style/font_faces'
import '../style/toast';

const application = Application.start();
const context = require.context('controllers', true, /mfa-sms_controller\.js|toast_controller\.js/);

application.load(definitionsFromContext(context));

require.context('../images/', true, /\.(gif|jpg|png|svg)$/i)

document.addEventListener('DOMContentLoaded', function() {
  let params = new URLSearchParams(document.location.search);
  let isEnabled = params.get('showMicrosoft');

  if (isEnabled != null) {
    localStorage.setItem('OpenRoadMicrosoftLogin', isEnabled);
  }

  let showMicrosoft = localStorage.getItem('OpenRoadMicrosoftLogin');

  if (showMicrosoft) {
    document.getElementById('microsoftLogin').classList.toggle('hidden', showMicrosoft === 'false');
  }
});
